import React from 'react';
import { graphql } from 'gatsby';
import Seo from '../components/organisms/Seo/Seo';
import Layout from '../components/organisms/Layout/Layout';
import Breadcrumbs from '../components/atoms/Breadcrumbs/Breadcrumbs';
import PageBannerPolicy from '../components/atoms/PageBannerPolicy/PageBannerPolicy';
import * as styles from './why-lavo.module.css';
import Container from '../components/atoms/Container/Container';
import { ParallaxProvider } from 'react-scroll-parallax';

const LavoDigitalContentful = ({ data }) => {
  // console.log(data);
  const {
    pageName,
    pageTitle,
    metaTitle,
    bannerBackgroundTheme,
  } = data.lavoDigitalPage;
  const page = data.lavoDigitalPage;
  const firstBlock = (page.blocks && page.blocks[0]) || false;

  const contentfulSections = (firstBlock && firstBlock.blocks) || false;

  // const blockPaddingTopBottom = (firstBlock.paddingTopBottom && firstBlock.paddingTopBottom.paddingTopBottom.paddingTopBottom) || false;
  const layoutStyle = (page.logoStyle && page.logoStyle.logoStyle) || false;
  const genClass = pageName
    ? 'contentful-page page-' +
      pageName
        .trim()
        .replace('.', '')
        .replace(/[^A-Z0-9]+/gi, '-')
        .toLowerCase()
    : '';
  const dataHelmet = { pageName, pageTitle, metaTitle };
  const seoTitle = page.seoTitle || page.pageName;

  // const handleIconClick = () => {
  //   console.log('test');
  // }
  // const { width } = useWindowDimensions();
  // const isMobile = width < 768;
  // console.log(firstBlock)
  return (
    <ParallaxProvider>
      <Layout
        varHelmet={dataHelmet}
        genClass={genClass}
        layoutStyle={layoutStyle}
        className={styles.container}>
        <Seo
          title={seoTitle}
          description={page.metaDescription}
          image={
            page.metaPreviewImage
              ? `https:${page.metaPreviewImage.file.url}`
              : ''
          }
        />
        <PageBannerPolicy
          title={pageTitle}
          description={metaTitle}
          theme={bannerBackgroundTheme[0]}
        />
        <Container size='medium'>
          <Breadcrumbs
            crumbs={[{ link: '/', label: 'Home' }, { label: 'Our Technology' }]}
          />
          <h3 className={styles.heading3} style={{ marginTop: 0 }}>
            {contentfulSections[0].heading}
          </h3>
          <div
            className={styles.mainContentWrapper}
            dangerouslySetInnerHTML={{
              __html: contentfulSections[0].content.childMarkdownRemark.html,
            }}
            style={{ paddingTop: 0 }}></div>
        </Container>
        <div className={styles.whyLavoFullWidthImageWrapper}>
          <div className={styles.whyLavoHeroImage}>
            <img
              src={contentfulSections[1].backgroundImage.desktopImage.file.url}
              alt=''
            />
          </div>
        </div>
      </Layout>
    </ParallaxProvider>
  );
};

export default LavoDigitalContentful;
export const query = graphql`
  query ContentfulPageLavoDigital {
    lavoDigitalPage: contentfulPage(pageName: { eq: "LAVO Digital" }) {
      pageName
      seoTitle
      pageTitle
      metaCanonicalLink
      metaDescription
      metaTitle
      bannerBackgroundTheme
      metaPreviewImage {
        file {
          url
        }
      }
      breadcrumbs
      logoStyle {
        logoStyle
      }
      blocks {
        textColour
        backgroundColour
        alignment {
          alignment
        }
        blockWidth {
          blockWidth
        }
        paddingTopBottom {
          paddingTopBottom {
            paddingTopBottom
          }
        }
        blocks {
          ... on ContentfulBlocks {
            blocks {
              ... on ContentfulHoverBlock {
                icon {
                  file {
                    url
                    fileName
                  }
                }
                content {
                  raw
                }
                heading
                iconText
              }
            }
          }
          ... on ContentfulBlockImage {
            id
            desktopImage {
              file {
                fileName
                url
              }
            }
            mobileImage {
              file {
                fileName
                url
              }
            }
          }
          ... on ContentfulBlockButtons {
            name
            internal {
              type
            }
            buttons {
              name
              buttonText
              deepLink
              url
            }
            paddingTopBottom {
              paddingTopBottom {
                paddingTopBottom
              }
            }
            blockWidth {
              blockWidth {
                blockWidth
              }
            }
          }
          ... on ContentfulBlockSlides {
            name
            classes
            internal {
              type
            }
            blocks {
              name
              blockWidth {
                blockWidth
              }
              alignment {
                alignment
              }
            }
            backgroundColour
            slide {
              name
              heading
              subText {
                childMarkdownRemark {
                  html
                }
              }
              image {
                altText
                desktopImage {
                  gatsbyImageData
                  file {
                    url
                  }
                }
                mobileImage {
                  gatsbyImageData
                  file {
                    url
                  }
                }
                imageMaxWidth
              }
              vimeoId
              backgroundColor
              button {
                buttonText
                deepLink
                name
                url
              }
              coloredSubText {
                textColor
                subText
              }
            }
            buttonName
            buttonUrl
            heading
            slideStyle {
              slideStyle
            }
            slideToShow {
              slideToShow
            }
            textColour {
              textColour {
                textColour
              }
            }
            blockWidth {
              blockWidth {
                blockWidth
              }
            }
            textAlignment {
              textAlignment
            }
          }
          ... on ContentfulBlockSingleCard {
            name
            internal {
              type
            }
            paddingTopBottom {
              paddingTopBottom {
                paddingTopBottom
              }
            }
            backgroundColour
            vimeoId
            content {
              name
              blockWidth {
                blockWidth {
                  blockWidth
                }
              }
              blockAlignment {
                blockAlignment
              }
              buttonName
              buttonUrl
              buttonStyle {
                buttonStyle
              }
              heading
              headingSize {
                headingSize
              }
              imagePosition {
                imagePosition
              }
              imageHoverAnimation {
                imageHoverAnimation
              }
              backgroundImage {
                altText
                imageMaxWidth
                desktopImage {
                  gatsbyImageData
                  file {
                    url
                  }
                }
                mobileImage {
                  gatsbyImageData
                  file {
                    url
                  }
                }
              }
              textAlignment {
                textAlignment
              }
              textColour
              content {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
          ... on ContentfulBlockDoubleCards {
            name
            internal {
              type
            }
            title
            backgroundColour
            textColour {
              textColour {
                textColour
              }
            }
            backgroundImage {
              altText
              imageMaxWidth
              desktopImage {
                gatsbyImageData
                file {
                  url
                }
              }
              mobileImage {
                gatsbyImageData
                file {
                  url
                }
              }
            }
            blocks {
              alignment {
                alignment
              }
              blockWidth {
                blockWidth
              }
              backgroundColour
            }
            blockWidth {
              blockWidth {
                blockWidth
              }
            }
            paddingTopBottom {
              paddingTopBottom {
                paddingTopBottom
              }
            }
            blockAlignment {
              blockAlignment
            }
            mobileRevert
            contentLeft {
              heading
              headingColor
              childContentfulBlockContentContentTextNode {
                childMarkdownRemark {
                  html
                }
              }
              buttonName
              buttonUrl
              buttonStyle {
                buttonStyle
              }
              name
              blockWidth {
                blockWidth {
                  blockWidth
                }
              }
              blockAlignment {
                blockAlignment
              }
              backgroundImage {
                altText
                imageMaxWidth
                desktopImage {
                  gatsbyImageData
                  file {
                    url
                  }
                }
                mobileImage {
                  gatsbyImageData
                  file {
                    url
                  }
                }
              }
              headingSize {
                headingSize
              }
              textAlignment {
                textAlignment
              }
              textColour
              imagePosition {
                imagePosition
              }
              imageHoverAnimation {
                imageHoverAnimation
              }
              backgroundColour
            }
            contentRight {
              heading
              childContentfulBlockContentContentTextNode {
                childMarkdownRemark {
                  html
                }
              }
              buttonName
              buttonUrl
              buttonStyle {
                buttonStyle
              }
              name
              blockWidth {
                blockWidth {
                  blockWidth
                }
              }
              blockAlignment {
                blockAlignment
              }
              backgroundImage {
                altText
                imageMaxWidth
                desktopImage {
                  gatsbyImageData
                  file {
                    url
                  }
                }
                mobileImage {
                  gatsbyImageData
                  file {
                    url
                  }
                }
              }
              headingSize {
                headingSize
              }
              textAlignment {
                textAlignment
              }
              textColour
              imagePosition {
                imagePosition
              }
              imageHoverAnimation {
                imageHoverAnimation
              }
              backgroundColour
            }
          }
          ... on ContentfulBlockContent {
            name
            backgroundColour
            blockWidth {
              blockWidth {
                blockWidth
              }
            }
            blockAlignment {
              blockAlignment
            }
            buttonName
            buttonUrl
            buttonStyle {
              buttonStyle
            }
            heading
            headingColor
            headingSize {
              headingSize
            }
            imagePosition {
              imagePosition
            }
            imageHoverAnimation {
              imageHoverAnimation
            }
            backgroundImage {
              altText
              imageMaxWidth
              desktopImage {
                gatsbyImageData
                file {
                  url
                }
              }
              mobileImage {
                gatsbyImageData
                file {
                  url
                }
              }
            }
            textAlignment {
              textAlignment
            }
            imagePosition {
              imagePosition
            }
            internal {
              type
            }
            content {
              childMarkdownRemark {
                html
              }
            }
          }
          ... on ContentfulBlockAniParallaxScrolling {
            name
            heading
            blockWidth {
              blockWidth {
                blockWidth
              }
            }
            internal {
              type
            }
            backgroundColour
            backgroundImage {
              imageMaxWidth
              altText
              desktopImage {
                gatsbyImageData
                file {
                  url
                }
              }
              mobileImage {
                gatsbyImageData
                file {
                  url
                }
              }
            }
            textColour {
              textColour {
                textColour
              }
            }
            style {
              style
            }
            stickyContent {
              headingSize {
                headingSize
              }
              imageHoverAnimation {
                imageHoverAnimation
              }
              imagePosition {
                imagePosition
              }
              name
              textAlignment {
                textAlignment
              }
              textColour
              heading
              className
              buttonName
              buttonUrl
              buttonStyle {
                buttonStyle
              }
              content {
                childMarkdownRemark {
                  html
                }
              }
              backgroundColour
              backgroundImage {
                imageMaxWidth
                altText
                desktopImage {
                  gatsbyImageData
                  file {
                    url
                  }
                }
                mobileImage {
                  gatsbyImageData
                  file {
                    url
                  }
                }
              }
              blockAlignment {
                blockAlignment
              }
              blockWidth {
                blockWidth {
                  blockWidth
                }
              }
            }
            scrollingContents {
              headingSize {
                headingSize
              }
              imageHoverAnimation {
                imageHoverAnimation
              }
              imagePosition {
                imagePosition
              }
              name
              textAlignment {
                textAlignment
              }
              textColour
              heading
              headingColor
              className
              buttonName
              buttonUrl
              buttonStyle {
                buttonStyle
              }
              content {
                childMarkdownRemark {
                  html
                }
              }
              backgroundColour
              backgroundImage {
                imageMaxWidth
                altText
                desktopImage {
                  gatsbyImageData
                  file {
                    url
                  }
                }
                mobileImage {
                  gatsbyImageData
                  file {
                    url
                  }
                }
              }
              blockAlignment {
                blockAlignment
              }
              blockWidth {
                blockWidth {
                  blockWidth
                }
              }
            }
          }
          ... on ContentfulBlockTiles {
            name
            internal {
              type
            }
            blockAlignment {
              blockAlignment
            }
            blockWidth {
              blockWidth {
                blockWidth
              }
            }
            blockStyle {
              blockStyle
            }
            backgroundColour
            textColour {
              textColour {
                textColour
              }
            }
            title
            tile {
              ... on ContentfulBlockContent {
                name
                blockWidth {
                  blockWidth {
                    blockWidth
                  }
                }
                buttonName
                buttonUrl
                buttonStyle {
                  buttonStyle
                }
                heading
                headingSize {
                  headingSize
                }
                imagePosition {
                  imagePosition
                }
                imageHoverAnimation {
                  imageHoverAnimation
                }
                backgroundImage {
                  altText
                  imageMaxWidth
                  desktopImage {
                    gatsbyImageData
                    file {
                      url
                    }
                  }
                  mobileImage {
                    gatsbyImageData
                    file {
                      url
                    }
                  }
                }
                textAlignment {
                  textAlignment
                }
                content {
                  childMarkdownRemark {
                    html
                  }
                }
              }
              ... on ContentfulBlockDoubleCards {
                name
                internal {
                  type
                }
                backgroundColour
                textColour {
                  textColour {
                    textColour
                  }
                }
                blocks {
                  alignment {
                    alignment
                  }
                  blockWidth {
                    blockWidth
                  }
                }
                blockWidth {
                  blockWidth {
                    blockWidth
                  }
                }
                blockAlignment {
                  blockAlignment
                }
                mobileRevert
                contentLeft {
                  heading
                  childContentfulBlockContentContentTextNode {
                    childMarkdownRemark {
                      html
                    }
                  }
                  buttonName
                  buttonUrl
                  buttonStyle {
                    buttonStyle
                  }
                  name
                  blockWidth {
                    blockWidth {
                      blockWidth
                    }
                  }
                  backgroundImage {
                    altText
                    imageMaxWidth
                    desktopImage {
                      gatsbyImageData
                      file {
                        url
                      }
                    }
                    mobileImage {
                      gatsbyImageData
                      file {
                        url
                      }
                    }
                  }
                  headingSize {
                    headingSize
                  }
                  textAlignment {
                    textAlignment
                  }
                  textColour
                  imagePosition {
                    imagePosition
                  }
                  imageHoverAnimation {
                    imageHoverAnimation
                  }
                }
                contentRight {
                  heading
                  childContentfulBlockContentContentTextNode {
                    childMarkdownRemark {
                      html
                    }
                  }
                  buttonName
                  buttonUrl
                  buttonStyle {
                    buttonStyle
                  }
                  name
                  blockWidth {
                    blockWidth {
                      blockWidth
                    }
                  }
                  backgroundImage {
                    altText
                    imageMaxWidth
                    desktopImage {
                      gatsbyImageData
                      file {
                        url
                      }
                    }
                    mobileImage {
                      gatsbyImageData
                      file {
                        url
                      }
                    }
                  }
                  headingSize {
                    headingSize
                  }
                  textAlignment {
                    textAlignment
                  }
                  textColour
                  imagePosition {
                    imagePosition
                  }
                  imageHoverAnimation {
                    imageHoverAnimation
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
